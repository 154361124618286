import React from 'react'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { I18n } from '@front/volcanion'

import CompanyContractList from './List'

const CompanyContract = ({ record_id }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('account.company')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <CompanyContractList record_id={record_id} />
    </Box>
  </StepContent>
</>

export default React.memo(CompanyContract)
