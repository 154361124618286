import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { user_id } = hooks.useParams()
  const [handleWelcomeAction] = hooks.useModelFunction('stage', 'handleWelcomeAction')

  const formProps = {
    name: 'user_form',
    model_name: 'user',
    record_id: user_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(handleWelcomeAction), [handleWelcomeAction]),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
    populate: [
      'auth',
      'info',
      'preference',
      'customerinfo.driver_options',
      'customerinfo.vehicle_options',
      'customerinfo.customer_billed_id.info',
      'customerinfo.customer_billed_id.customerinfo',
    ],
    initialState: { isReadOnly: !!user_id },
    submitChangesOnly: false
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
