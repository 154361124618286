import React, { useEffect } from 'react'
import { I18n, hooks } from '@front/volcanion'


const withContainer = Component => props => {
  const mergedProps = {

  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
